import App from './App'
import ReactDOM from 'react-dom'
import 'src/utils/chart'
import * as serviceWorker from './serviceWorker'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import 'nprogress/nprogress.css'
import { SidebarProvider } from './contexts/SidebarContext'
import { UserProvider } from './state/contexts/user'
import { EnvironmentProvider } from './contexts/Environment'

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <EnvironmentProvider>
        <BrowserRouter>
          <UserProvider>
            <App />
          </UserProvider>
        </BrowserRouter>
      </EnvironmentProvider>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
