import { Box, Button, Container, Grid, Typography } from '@mui/material'

import { Link as RouterLink } from 'react-router-dom'

import { styled } from '@mui/material/styles'

import glitch from '../../../assets/glitch.png'
import myst from '../../../assets/myst.png'
import selego from '../../../assets/selego.png'

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(50)};
`,
)

const TypographyH2 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`,
)

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.success.main};
    color: ${theme.palette.success.contrastText};
    font-weight: bold;
    border-radius: 30px;
    text-transform: uppercase;
    display: inline-block;
    font-size: ${theme.typography.pxToRem(11)};
    padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};
    margin-bottom: ${theme.spacing(2)};
`,
)

const MuiAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: ${theme.general.borderRadius};
    background-color: #e5f7ff;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto ${theme.spacing(2)};

    img {
      width: 60%;
      height: 60%;
      display: block;
    }
`,
)

const TsAvatar = styled(Box)(
  ({ theme }) => `
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: ${theme.general.borderRadius};
    background-color: #dfebf6;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto ${theme.spacing(2)};

    img {
      width: 60%;
      height: 60%;
      display: block;
    }
`,
)

type Product = {
  image: string
  title: string
  description: string
}

const products: Product[] = [
  {
    title: 'Myst',
    description: 'Curated Horror, Thriller, and Suspense Movies & Audiobooks',
    image: myst,
  },
  {
    title: 'Glitch',
    description: 'Adult animated movies, short films, and podcasts.',
    image: glitch,
  },
  {
    title: 'Selego',
    description:
      'Explore hand-picked independent films, series, audiobooks, podcasts & documentaries.',
    image: selego,
  },
]

function Hero() {
  return (
    <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
      <Grid justifyContent="center" alignItems="center" container>
        {products.map(({ image, title, description }: Product) => (
          <Grid item md={4}>
            <MuiAvatar>
              <img src={image} alt="Material-UI" />
            </MuiAvatar>
            <Typography variant="h4">
              <Box sx={{ pb: 2 }}>
                <b>{title}</b>
              </Box>
              <Typography
                component="span"
                variant="subtitle2"
                sx={{ padding: '0 20px' }}
              >
                {description}
              </Typography>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Hero
