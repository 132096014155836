import React, { createContext } from 'react'

import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { User } from 'src/models/user.model'

type IUserContext = [User, React.Dispatch<React.SetStateAction<User>>]

export const UserContext = createContext<IUserContext>(undefined)

export const UserProvider: React.FC = (props): JSX.Element => {
  const [user, setUser] = useLocalStorage('@user', null)

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = React.useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
