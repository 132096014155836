import { Box, Switch, Typography } from '@mui/material'
import { useEnvironment } from 'src/contexts/Environment'

const EnvironmentSwitch: React.FC<{}> = (): JSX.Element => {
  const [environment, setEnvironment] = useEnvironment()

  const renderEnvironmentText = () =>
    environment === 'development' ? 'Desarrollo' : 'Producción'

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={environment === 'development' ? 'row' : 'row-reverse'}
      sx={{ width: 300 }}
    >
      <Typography
        variant="body1"
        sx={{
          color: 'text.secondary',
          fontWeight: 'bold',
          mr: 1,
          width: '25%',
        }}
      >
        {renderEnvironmentText()}
      </Typography>

      <Switch
        sx={{
          zIndex: 10,
        }}
        checked={environment === 'production'}
        color="primary"
        onChange={(e: any, checked: boolean) => {
          setEnvironment(checked ? 'production' : 'development')
        }}
      />

      <Typography
        variant="body1"
        sx={{
          color: 'text.secondary',
          fontWeight: 'bold',
          mr: 1,
          width: '25%',
        }}
      ></Typography>
    </Box>
  )
}
export default EnvironmentSwitch
