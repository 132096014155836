import React, { createContext } from 'react'
import { useLocalStorage } from 'src/hooks/useLocalStorage'

export type Environment = 'development' | 'production'

type IEnvironmentContext = [
  Environment,
  React.Dispatch<React.SetStateAction<Environment>>,
]

export const EnvironmentContext = createContext<IEnvironmentContext>([
  'development',
  () => {},
])

EnvironmentContext.displayName = 'EnvironmentContext'

type EnvironmentProviderProps = {
  children: JSX.Element[] | JSX.Element
}

export const EnvironmentProvider: React.FC<EnvironmentProviderProps> = (
  props,
): JSX.Element => {
  const [environment, setEnvironment] = useLocalStorage(
    '@environment',
    'development',
  ) as IEnvironmentContext

  return (
    <EnvironmentContext.Provider
      value={[environment, setEnvironment]}
      {...props}
    >
      {props.children}
    </EnvironmentContext.Provider>
  )
}

export const useEnvironment = () => {
  const context = React.useContext(EnvironmentContext)

  if (!context) {
    throw new Error('useEnvironment must be used within a EnvironmentProvider')
  }
  return context
}
