import { Suspense, lazy } from 'react'
import { Navigate, RouteObject } from 'react-router'

import SidebarLayout from 'src/layouts/SidebarLayout'
import BaseLayout from 'src/layouts/BaseLayout'

import SuspenseLoader from 'src/components/SuspenseLoader'

import Hero from './routes/login/Hero'

const Loader = Component => props =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

// Pages
const Login = Loader(lazy(() => import('src/routes/login')))
const Users = Loader(lazy(() => import('src/routes/users')))
const Contents = Loader(lazy(() => import('src/routes/contents')))
const Products = Loader(lazy(() => import('src/routes/products')))
const Regions = Loader(lazy(() => import('src/routes/regions')))

export const guestRoutes: RouteObject[] = [
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/login" />,
      },
      {
        path: '*',
        element: <Navigate to="/login" />,
      },
      {
        path: '/login',
        element: <Login />,
      },
    ],
  },
]

export const authRoutes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '*',
        element: <Navigate to="/" />,
      },
      {
        path: '/',
        element: <Hero />,
      },
      {
        path: 'contents/:content_type',
        element: <Contents />,
      },
      {
        path: 'products/:product/:product_type',
        element: <Products />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'regions',
        element: <Regions />,
      },
    ],
  },
]
