import { useEffect } from 'react'

import { useRoutes } from 'react-router-dom'
import { guestRoutes, authRoutes } from './router'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import ThemeProvider from './theme/ThemeProvider'
import { CssBaseline } from '@mui/material'
import { useUser } from './state/contexts/user'

import { initializeApp } from 'firebase/app'

const App = () => {
  useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyDgY_Fuc0_UDD5_-BLOfWDuaOy6AB12ayY',
      authDomain: 'cms-tribesmedia.firebaseapp.com',
      projectId: 'cms-tribesmedia',
      storageBucket: 'cms-tribesmedia.appspot.com',
      messagingSenderId: '804523444599',
      appId: '1:804523444599:web:5203e57695edecae197972',
    }

    initializeApp(firebaseConfig)
  }, [])

  const [user] = useUser()

  const content = useRoutes(user ? authRoutes : guestRoutes)

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  )
}
export default App
